export const baseInfoCell = [
  {
    title: '公文標題',
    key: 'biaoti'
  },
  {
    title: '發文字號',
    key: 'documentNumber'
  },
  {
    title: '公文密級程度',
    key: 'miji',
    renderKey: 'flow_secrecy_degree'
  },
  {
    title: '公文緊急程度',
    key: 'jjcd',
    renderKey: 'flow_urgency_degree'
  },
  {
    title: '選擇分類',
    key: 'xzfn',
    renderKey: 'flow_file_type'
  }
]
export const personCell = [
  {
    title: '擬稿單位/人',
    key: 'draftId'
  },
  {
    title: '會稿單位/人',
    key: 'meetId'
  },
  {
    title: '核稿人',
    key: 'checkIds'
  },
  {
    title: '主送',
    key: 'mainIds'
  },
  {
    title: '抄送',
    key: 'sendIds'
  }
]
