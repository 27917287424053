//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { acceptDocumentBill } from '@/api/modular/fileStream/documentManage'
import upload from '@/views/fileStream/documentManage/upload.vue'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import minxin, { billDetail, initWx } from '../components/minxin'
import { baseInfoCell, personCell } from './config'
export default {
    mixins: [initWx, minxin, billDetail],
    components: {
        OpenData,
        upload,
    },
    computed: {
        fileSeeInfo() {
            const initFileSeeInfo = {
                seeStatusIds: {
                    title: '已預覽附件人員：',
                    val: [],
                },
                downloadIds: {
                    title: '已下載附件人員：',
                    val: [],
                },
                acceptIds: {
                    title: '已接受文件人員：',
                    val: [],
                },
            }
            this.billDetailInfo.acceptList.forEach((item) => {
                if (item.acceptStatus === 1) {
                    initFileSeeInfo.acceptIds.val.push(item)
                }
                if (item.downloadStatus === 1) {
                    initFileSeeInfo.downloadIds.val.push(item)
                }
                if (item.seeStatus === 1) {
                    initFileSeeInfo.seeStatusIds.val.push(item)
                }
            })
            return initFileSeeInfo
        },
    },
    data() {
        return {
            baseInfoCell,
            personCell,
        }
    },
    methods: {
        formatValue(i) {
            const val = this.myData[i.key]
            if (!val || val === 'null') return ''
            if (i.renderKey) return this.getValueByKey(this[i.renderKey], val)
            return val
        },
        /**
         * 接收公文
         */
        acceptBill() {
            acceptDocumentBill(this.$route.query.id)
                .then((res) => {
                    if (res.code === 200) {
                        this.$message.success('接收成功')
                        this.$nextTick(() => {
                            this.$router.go(-1)
                        })
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        formatPerson(ids) {
            if (!ids) return []
            return JSON.parse(ids)
        },
        getValueByKey(arr, s) {
            // eslint-disable-next-line eqeqeq
            const values = arr.filter((item) => item.code == s)
            if (values.length > 0) {
                return values[0].value
            }
        },
    },
}
